let demoList = {
    code: 0,
    id: 1,
    name: 'zs',
    age: '23',
    job: '前端工程师'
}

export default {
    'post|/user/login': option => {
        return {
            status: 200,
            message: 'success',
            data: demoList
        };
    }
}