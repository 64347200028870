import Vue from 'vue'
import Vuex from 'vuex'
import { Moneyinfo } from "@/https/mine_center.js";
import { getBanner } from "@/https/home.js";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        menu_page: {},
        font_size: 18,
        login_type: false,
        mine_menu: "",
        token: null,
        input_val: '',
        book_info: {},
        money_info: {},
        messageVue: new Vue(), //用于跨组件传递事件
    },
    mutations: {
        get_menu_page(state, data) {
            sessionStorage.setItem("menu_page", data)
            state.menu_page = data
        },
        set_font_size(state, data) {
            sessionStorage.setItem("font_size", data)
            state.font_size = data
        },
        set_login_type(state, data) {
            sessionStorage.setItem("login_type", data)
            state.login_type = data
        },
        set_mine_menu(state, data) {
            sessionStorage.setItem("mine_menu", JSON.stringify(data))
            state.mine_menu = data
        },
        set_token(state, data) {
            localStorage.setItem("api_token", data)
            state.token = data
        },
        set_bookdetail(state, data) {
            state.book_info = data || {};
        },
        set_money_info(state, data) {
            Vue.set(state, "money_info", data);
        },
        set_banner_info(state, data){
            Vue.set(state, "banner_info", data);
        }
    },
    actions: {
        updateMoneyinfo(context) {
            Moneyinfo().then(res => {
                if (res.code === 0) {
                    context.commit('set_money_info', res.data);
                }
            })
        },
        updateBannerInfo(context) {
            getBanner().then(res => {
                if (res.code === 0) {
                    context.commit('set_banner_info', res.data || []);
                }
            })
        },
    },
    getters: {
        aviod_menu_page: (state) => state.menu_page, //人员轨迹条件搜索 选项卡
        aviod_font_size: (state) => state.font_size, //小说字体大小
        aviod_login_type: (state) => state.login_type, //登录弹窗
        aviod_mine_menu: (state) => state.mine_menu, //我的中心菜单
        aviod_token_type: (state) => state.token,
        get_top_banner_info: (state) => ((state.banner_info || []).filter(item=>item.type === 7) || [])[0] || {},
        get_ad_banner_info: (state) => ((state.banner_info || []).filter(item=>item.type === 3) || [])[0] || {},
        get_mid_banner_info: (state) => ((state.banner_info || []).filter(item=>item.type === 2) || [])[0] || {},
        get_footer_banner_info: (state) => (state.banner_info || []).filter(item=>item.type === 6) || [],
        get_lunbo_banner_info: (state) => (state.banner_info || []).filter(item=>item.type === 1) || [],
    },
    modules: {}
})
