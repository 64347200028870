import store from '../store'
import Vue from 'vue'
import { get_Cookie } from "./cookie.js"



let person_info=JSON.parse(sessionStorage.getItem("person_info"))
const is_author = function () {
  if(person_info){
    sessionStorage.setItem("is_author",Number(person_info.is_author))
    return Number(person_info.is_author)===1?true:false
  }else{
    sessionStorage.setItem("is_author",0)
    return false
  }
}

const open_relation= function () {
  let relation=JSON.parse(sessionStorage.getItem("relation"))
  if(relation){
    return Number(relation.is_master)===1?true:false
  }else{
    return false
  }
}

const had_login = function () {
  // let token=sessionStorage.getItem("api_token")
  // let token = get_Cookie("api_token")
  let token = localStorage.getItem("api_token")
  if(token){
    return true
  }else{
    // store.commit("set_login_type",true)
    return false
  }
}
const resetSetItem = function (key, newVal) {
   if (key === 'book_id') {
       // 创建一个StorageEvent事件
       var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
           setItem: function (k, val) {
               sessionStorage.setItem(k, val);

                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        };
       return storage.setItem(key,newVal);
  }
}

Vue.prototype.had_login=had_login
Vue.prototype.is_author=is_author
Vue.prototype.open_relation=open_relation
Vue.prototype.resetSetItem = resetSetItem



// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(lottery)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:lottery.S")      ==> 2006-7-2 8:9:4.18
/* eslint-disable no-extend-native */
Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}
