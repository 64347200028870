import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        // redirect: '/index',
        component: () => import('@/views/index/index.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/views/main/main.vue'),
                meta: { name: '推荐', show_hidden: true, menu_id: 1 }
            },
        ]
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue'),
        children: [
            {
                path: '/',
                // redirect: '/main',
                component: () => import('@/views/main/main.vue'),
                meta: { name: '推荐', show_hidden: true, menu_id: 1 }
            },
            {
                path: '/main',
                name: 'main',
                component: () => import('@/views/main/main.vue'),
                meta: { name: '推荐', show_hidden: true, menu_id: 1 }
            },
            {
                path: '/stack_room',
                name: 'stack_room',
                component: () => import('@/views/stack_room/index.vue'),
                meta: { name: '书库', show_hidden: true, menu_id: 2 }
            },
            {
                path: '/ranking',
                name: 'ranking',
                component: () => import('@/views/ranking/index.vue'),
                meta: { name: '排行', show_hidden: true, menu_id: 3 }
            },
            {
                path: '/owner_center',
                name: 'owner_center',
                component: () => import('@/views/owner_center/index.vue'),
                meta: { name: '个人中心', show_hidden: true, menu_id: 4, need_login: true },
                children: [
                    {
                        path: '/',
                        redirect: '/mine_center',
                    },
                    {
                        path: '/mine_center',
                        name: 'mine_center',
                        component: () => import('@/views/owner_center/mine_center.vue'),
                        meta: { name: '个人中心', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/mine_shelf',
                        name: 'mine_shelf',
                        component: () => import('@/views/owner_center/mine_shelf.vue'),
                        meta: { name: '我的书架', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/author_center',
                        name: 'author_center',
                        component: () => import('@/views/owner_center/author_center.vue'),
                        meta: { name: '作家中心', show_hidden: true, menu_id: 4, need_login: true },
                        children: [
                            {
                                path: '/',
                                redirect: '/works_manage',
                            },
                            {
                                path: '/works_income',
                                name: 'works_income',
                                component: () => import('@/views/owner_center/components/works-income.vue'),
                                meta: { name: '作品收入', show_hidden: true, menu_id: 4, need_login: true },
                            },
                            {
                                path: '/withdrawal',
                                name: 'withdrawal',
                                component: () => import('@/views/owner_center/components/withdrawal.vue'),
                                meta: { name: '提现记录', show_hidden: true, menu_id: 4, need_login: true },
                            },
                            {
                                path: '/go_withdrawal',
                                name: 'go_withdrawal',
                                component: () => import('@/views/owner_center/components/go_withdrawal.vue'),
                                meta: { name: '提现', show_hidden: true, menu_id: 4, need_login: true },
                            },
                            {
                                path: '/new_works',
                                name: 'new_works',
                                component: () => import('@/views/owner_center/components/new_works.vue'),
                                meta: { name: '新增作品', show_hidden: true, menu_id: 4, need_login: true },
                            },
                            {
                                path: '/works_manage',
                                name: 'works_manage',
                                component: () => import('@/views/owner_center/components/works-manage.vue'),
                                meta: { name: '稿酬收入', show_hidden: true, menu_id: 4, need_login: true }
                            },
                            {
                                path: '/check_on',
                                name: 'check_on',
                                component: () => import('@/views/owner_center/components/checkon-admin.vue'),
                                meta: { name: '稿酬收入', show_hidden: true, menu_id: 4, need_login: true }
                            }
                        ]
                    },
                    {
                        path: '/friendly',
                        name: 'friendly',
                        component: () => import('@/views/owner_center/friendly.vue'),
                        meta: { name: '师徒&好友', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/mine_common',
                        name: 'mine_common',
                        component: () => import('@/views/owner_center/mine_common.vue'),
                        meta: { name: '我的评论', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/finance_center',
                        name: 'finance_center',
                        component: () => import('@/views/owner_center/finance_center.vue'),
                        meta: { name: '财务中心', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/recharge',
                        name: 'recharge',
                        component: () => import('@/views/owner_center/recharge.vue'),
                        meta: { name: '充值中心', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/owner_set',
                        name: 'owner_set',
                        component: () => import('@/views/owner_center/owner_set.vue'),
                        meta: { name: '个人设置', show_hidden: true, menu_id: 4, need_login: true }
                    },
                    {
                        path: '/ticket',
                        name: 'ticket',
                        component: () => import('@/views/owner_center/ticket.vue'),
                        meta: { name: '推荐票明细', show_hidden: true, menu_id: 4, need_login: true }
                    }
                ]
            }
        ]
    },
    {
        path: '/show',
        name: 'show',
        component: () => import('@/views/book_info/index.vue'),
        children: [
            {
                path: '/',
                redirect: '/show_book',
            },
            {
                path: '/show_book',
                name: 'show_book',
                component: () => import('@/views/book_info/book_index.vue'),
                children: [
                    {
                        path: '/',
                        redirect: 'book_home'
                    },
                    {
                        path: 'book_home',
                        name: 'book_home',
                        component: () => import('@/views/book_info/components/book_home.vue')
                    },
                    {
                        path: 'book_catlog',
                        name: 'book_catlog',
                        component: () => import('@/views/book_info/components/book_catlog.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/book_home',
        name: 'book_home',
        component: () => import('@/views/book_info/components/book_home.vue')
    },
    {
        path: '/show_author',
        name: '/show_author',
        component: () => import('@/views/author_info/index.vue')
    },
    {
        path: '/show_posters',
        name: 'show_posters',
        component: () => import('@/views/show_posters/show_posters.vue')
    },//海报展示页面
    {
        path: '/download',
        name: 'download',
        component: () => import('@/views/download/download.vue')
    },//下载页面
    {
        path: '/read_book',
        name: 'read_book',
        component: () => import('@/views/read_book/index.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search/search.vue')
    },
    {
        path: '/guid',
        name: 'guid',
        component: () => import('@/views/guid/guid.vue')
    },
    {
        path: '/go_register',
        name: 'go_register',
        component: () => import('@/views/reg_author/go_register.vue')
    },
    {
        path: '/write_detail',
        name: 'write_detail',
        component: () => import('@/views/reg_author/write_detail.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/index/privacy.vue')
    },
    {
        path: '/service',
        name: 'service',

        component: () => import('@/views/index/service.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/index/register.vue')
    },
    {
        path: '/write_center',
        name: 'write_center',
        component: () => import('@/views/writer/write_center.vue')
    },
    {
        path: '/forget_pas',
        name: 'forget_pas',
        component: () => import('@/views/index/forget_pas.vue')
    },
    {
        path: '/show_photo',
        name: 'show_photo',
        component: () => import('@/views/index/photo_exhibition.vue')
    },
    {
        path: '/show_up_pic',
        name: 'show_up_pic',
        component: () => import('@/components/input/up_pic2')
    },
    {
        path: '*',
        name: '404',
        component: () => import('@/views/index/404.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
export default router


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
