import {request} from "./config"

let NewUrl = ''
//个人中心获取个人基本信息
export const PersonInfo = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/PersonInfo',
    data: params
})

//个人中心个人基本信息修改
export const PersonUpbase = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/personupbase',
    data: params
})

// 个人中心-财务中心-用户信息
export const Moneyinfo = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/moneyinfo',
    data: params
})

// 个人设置接口-财务中心-记录
export const Recordinfo = (params) => {
    let url = NewUrl + 'admin/user/recordinfo';
    if(params.table === 1){
        url = NewUrl + 'api/users/expenseRecord';
    }
    return request({
        method: 'post',
        url,
        data: params
    });
}
// 个人中心-我的书架
export const Mybook = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/mybooks',
    data: params
})
// 个人设置接口-我的书架-删除
export const Booksdelete = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/books_delete',
    data: params
})

// 个人中心--个人中心
export const Personcenter = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/personcenter',
    data: params
})

// 个人中心--我的评论
export const Comment = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/comment_book',
    data: params
})
// 个人中心--我的评论--展示评论(作家回复)
export const Comment_author = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/reply',
    data: params
})
// 个人中心--删除评论
export const Delcomment = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/comments_del',
    data: params
})
// 个人中心--获取当前书籍评论
export const Comments = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/comments',
    data: params
})

// 个人中心-师徒&好友
export const Marsts = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/marsts',
    data: params
})

// 个人中心-充值中心
export const Buycenter = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/buycenter',
    data: params
})

// 个人中心-充值中心
export const aviod_pay = (params) => request({
    method: 'get',
    url: NewUrl + 'web/pay',
    data: params
})

// 个人中心-充值中心
export const is_pay = (params) => request({
    method: 'post',
    url: NewUrl + 'is_pay',
    data: params
})
// admin/user/authorcenter
// 个人中心-作家中心-数据信息
export const AuthorCenter = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/authorcenter',
    data: params
})

//个人中心-作家中心-作品设置与新建作品
export const AuthorcUp = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/authorcenterup',
    data: params
})

//个人中心-作家中心-作品设置与新建作品
export const Sendmassage = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/sendmassage',
    data: params
})
//个人中心-作家中心-作品设置与新建作品
export const Labels = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/labels',
    data: params
})
//个人中心-作家中心-作品设置与新建作品
export const ChactCode = (params) => request({
    method: 'post',
    url: NewUrl + 'admin/user/chactcode',
    data: params
})
//个人中心-作家中心-作品设置与新建作品
export const getsexlabels = (params) => request({
    method: 'get',
    url: NewUrl + 'admin/user/getsexlabels',
    data: params
})
//个人中心-作家中心-获取作品所有标签
export const getAllLabel = () => request({
    method: 'post',
    url: NewUrl + '/api/books/getAllLabels',
})

//个人中心-作家中心-稿酬收入
export const author_record_info = (params) => request({
    method: 'get',
    url: NewUrl + 'web/user/author_record_info',
    data: params
})

//个人中心-作家中心-稿酬收入-提现记录
export const withdraw_history = (params) => request({
    method: 'get',
    url: NewUrl + 'web/user/withdraw_history',
    data: params
})

//个人中心-作家中心-稿酬收入-申请提现
export const go_withdraw = (params) => request({
    method: 'get',
    url: NewUrl + 'web/user/withdraw',
    data: params
})

//个人中心-作家中心-稿酬收入-获取作家作品
export const author_books = () => request({
    method: 'get',
    url: NewUrl + 'web/user/author_books',
})

//个人中心-师徒好友-接受拒绝拜师申请
export const AcceptMaster = (params) => request({
    method: 'get',
    url: NewUrl + 'web/user/accept_master',
    data: params
})

//用户月票记录
export const getTicketRecord = (params) => request({
    method: 'post',
    url: 'api/ticket/userMonthTicketRecord',
    data: params
})

//用户月票记录
export const getAttendance = (params) => request({
    method: 'post',
    url: 'api/writes/attendance',
    data: params
})
