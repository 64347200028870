import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './https/config'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import CPage from './components/page/c-page.vue';
import VIPShow from './components/main/vip_free.vue';
import NoneData from './components/main/un_data.vue';
import "./assets/css/common.css";
import "./utils/had_login.js" //判断是否登录
import "./utils/date.js"
import "./utils/cookie.js"
import { get_Cookie } from "./utils/cookie.js"
import VueCropper from 'vue-cropper'
import 'babel-polyfill'

require('./mock');
Vue.use(ElementUI);
Vue.use(VueCropper);//图片裁剪
Vue.component('CPage', CPage)
Vue.component('VIPShow', VIPShow)
Vue.component('NoneData', NoneData)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // let token=get_Cookie("api_token")
  let token = localStorage.getItem('api_token')
  if (to.meta.need_login) {
    if (token) {
      next()
    } else {
      next({
        path: '/index',
      })
    }
  }
  if (to.meta.menu_id) {
    sessionStorage.setItem("menu_id", to.meta.menu_id)
  }

  if ((to.path == '/write_center' || to.path == '/author_center') && !Vue.prototype.is_author()) {
    alert('您还不是作家，请先注册')
    next('/index');
  }
  next()
})

new Vue({
  store,
  router,
  mounted() {
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 10000)
  },
  render: h => h(App)
}).$mount('#app')
