import axios from "axios";
// import QS from 'qs';
import store from '../store'
import router from '../router'
import { get_Cookie } from "../utils/cookie.js"
import {
    MessageBox,
    Message
} from 'element-ui'

let cutFlag = false
let exitFlag = false
//  设置网络的 超时时间
axios.defaults.timeout = 10000

//设置post 的请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'


// 请求拦截
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断sessionStorage/cookie中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = sessionStorage.getItem('api_token')
        // const token = get_Cookie("api_token")
        const token = localStorage.getItem('api_token')
        token && (config.headers.Authorization = token || '');
        return config;
    },
    error => {
        return Promise.error(error);
    })


// 响应拦截
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        }
        if (response.status === 500) {
            return Promise.reject("服务器报错");
        }
        if (response.status === 1001) {
            // router.push({
            //     path: '/login'
            // })
            // Message.warning("登录已过期，请重新登录")
            store.commit("set_login_type", true)
            return Promise.reject("token已过期");
        }
    }
);

function loginOverTime() {
    let href = window.location.href
    if (href.endsWith('/login') || href.endsWith('/')) {
        exitFlag = true
    }
    if (!exitFlag) {
        // MessageBox.alert('登录已过期，请重新登录！', '温馨提示', {
        //     confirmButtonText: '确定',
        //     type: 'warning',
        //     callback: action => {
        //         // router.push('/index')
        //         store.commit("set_login_type",true)
        //     }
        // })
        Message.warning("登录已过期，请重新登录")
        store.commit("set_login_type", true);
        exitFlag = true
    }
}

function need_login() {
    if (!exitFlag) {
        MessageBox.alert('书籍需要付费请先登录！', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
                store.commit("set_login_type", true)
            }
        })
        exitFlag = true
    }
}


//封装网络请求
let baseURL = ''
// 设置网络请求的默认地址， （根据环境来替换地址）
if (process.env.NODE_ENV == 'development') {
    if (location.href.includes('localhost') || location.href.includes('jytest_pc')) {
        baseURL = 'http://jytestapi.qxsclass.com/';//测试
    } else {
        baseURL = 'https://jyapi.jyacg.com/';//正式服
    }
} else if (process.env.NODE_ENV == 'production') {
    //生产环境
    // baseURL = 'https://lytest.qxsclass.com/';//正式服
    if (location.href.includes('jytest_pc')) {
        baseURL = 'http://jytestapi.qxsclass.com/';
    } else {
        baseURL = 'https://jyapi.jyacg.com/';//正式服
    }
}

/**
 * 封装axios的通用请求
 * @param  {string}   method     get、post....(默认post)
 * @param  {string}   url       请求的接口URL
 * @param  {object}   data      传的参数
 * @param  {object}   options     请求选项,options.accessToken === null 时随机生成字符串作为accessToken
 * @returns {Promise.<TResult>|*}
 */

export const request = ({
    method = 'post',
    url,
    data,
    options = {}
} = {}) => {
    if (url.indexOf('login') >= 0) {
        console.log(1)
    } else {
        axios.defaults.headers.Authorization = localStorage.getItem('api_token') || ''
        // 账号存在多个社区，选择不同社区后，相应接口都需要传社区id
        // let userData = JSON.parse(sessionStorage.getItem('userData')) || store.state.account
        data = {
            ...data
            // community_id: userData.community_id
        }
    }
    url = baseURL + `${url}`
    let param1
    let param2
    if (['get', 'head', 'delete', 'jsonp'].indexOf(method.toLocaleLowerCase()) !== -1) {
        options.params = {
            ...data,
            ...options.params
        }
        param1 = {
            ...options,
            body: data
        }
    } else {
        param1 = data
        param2 = options
    }
    // debug('请求：', url, '\n参数：', data)
    return new Promise((resolve, reject) => {
        axios[method](url, param1, param2).then((response) => {
            /* 正式接口使用 */
            // if (response.status === 200) {

            /* mook接口使用 */
            if (response.status === 200) {
                switch (response.data.code) {/* 正式接口使用 */
                    // switch (response.data.data.code) {
                    /* mook接口使用 */
                    case 1001:
                        loginOverTime()
                        reject(response.data)
                        return
                    case 1005:
                        // need_login()
                        Message.warning(response.data.msg);
                        reject(response.data)
                        return
                    case 10001:
                        Message.warning(response.data.msg)
                        return
                    default:
                        exitFlag = false
                        resolve(response.data)
                        return
                }
            }
        }).catch(({
            response
        }) => {
            if (!cutFlag && response) {
                let data = response.data
                let status = response.status
                if (status === 500) {
                    let code = data.code
                    if (code === 404) {
                        MessageBox.alert('找不到系统资源，请稍后再试！', '温馨提示', {
                            confirmButtonText: '确定',
                            type: 'warning',
                            callback: action => {
                                cutFlag = false
                            }
                        })
                    } else {
                        conole.log(2222);
                        MessageBox.alert('服务器异常，请稍后再试！', '温馨提示', {
                            confirmButtonText: '确定',
                            type: 'warning',
                            callback: action => {
                                cutFlag = false
                            }
                        })
                    }
                } else if (status === 504) {  
                    MessageBox.alert('与服务器断开链接，请稍后再试！', '温馨提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            cutFlag = false
                        }
                    })
                } else {
                    conole.log(11111);
                    MessageBox.alert('服务器异常，请稍后再试！', '温馨提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                            cutFlag = false
                        }
                    })
                }
            }
            cutFlag = true
        })
    })
}
