import {request} from "./config"

// let NewUrl = 'http://192.168.0.128:81'
let NewUrl = ''

// 首页1
export const home_one = (params) => request({
    method: 'get',
    url: NewUrl + 'web/index',
    data: params
})

// 首页2
export const home_two = (params) => request({
    method: 'get',
    url: NewUrl + 'web/index_sec',
    data: params
})

// 首页3
export const home_three = (params) => request({
    method: 'get',
    url: NewUrl + 'web/index_third',
    data: params
})

// 获取banner图
export const getBanner = (params) => request({
    method: 'post',
    url: NewUrl + 'api/common/bannerList',
    data: params
})


