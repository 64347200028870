<template>
  <div class="cPage">
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.limit"
      :total="page.total"
      background="background"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "CPage",
  props: {
    callBack: Function,
    page: {
      type: [Number, Object],
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleSizeChange(val) {
      this.page.limit = val;
      this.callBack(this.page.page, val);
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.callBack(val, this.page.limit);
    },
  },
};
</script>

<style scoped>
.cPage {
  text-align: end;
  box-sizing: border-box;
  margin-top: 8px;
}
</style>
