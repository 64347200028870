<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import {relation_open} from "@/https/api.js";

export default {
    name: "App",
    created() {
        this.aviod_info();
        setTimeout(()=>{
            this.$store.dispatch('updateBannerInfo');
            this.$store.dispatch('updateMoneyinfo');
        })
    },
    methods: {
        aviod_info() {
            relation_open().then((res) => {
                if (res.code === 0) {
                    sessionStorage.setItem("relation", JSON.stringify(res.data));
                    document.cookie = `relation=${JSON.stringify(res.data)}`;
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
    },
};
</script>


<style lang="scss">
// @import "./assets/css/media.css";
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Microsoft YaHei, SimSun, MingLiU;
}

body {
    background-color: #FAFCFF;
}

#app {
    font-size: 14px;
    color: #333333;
    background-color: #FAFCFF;
}

/* body {
  position: relative;
} */
/deep/ .el-message-box__wrapper {
    position: absolute !important;
}
</style>
