<template>
  <div class="vip_show" v-show="is_vip || is_free">
    <p class="vip_p" v-if="is_vip || (is_vip && is_free)" :style="css_style">
      VIP
    </p>
    <p class="free_p" v-if="!is_vip && is_free" :style="css_style">付费</p>
  </div>
</template>
<script>
export default {
  name: "VIPShow",
  props: {
    is_vip: {
      type: Number,
    },
    is_free: {
      type: Number,
    },
    css_style: {
      type: Object,
    },
  },
};
</script>
<style scoped>
.vip_show {
  position: absolute;
  width: 27%;
  height: 9%;
  top: 2px;
  right: 2px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  z-index: 180;
}
.vip_p,
.free_p {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  opacity: 0.8;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vip_p {
  background-color: #ff4949;
}
.free_p {
  background-color: #f99617;
}
</style>