import {request} from "./config"


// let NewUrl = 'http://192.168.0.128:81'
let NewUrl = ''
//登录
export const login = (params) => request({
    method: 'post',
    url: NewUrl + 'web/login',
    data: params
})

//验证码登录
export const captcha_login = (params) => request({
    method: 'post',
    url: NewUrl + 'web/captcha_login',
    data: params
})

//验证码修改密码
export const forget_passwd = (params) => request({
    method: 'post',
    url: NewUrl + 'web/forget_passwd',
    data: params
})

// 图片上传
export const upload_img = (params) => request({
  method: 'post',
  url: NewUrl + 'api/upload/image',
  data: params
})

// 注册
export const register = (params) => request({
  method: 'post',
  url: NewUrl + 'web/register',
  data: params
})

// 微信绑定手机
export const bindPhone = (params) => request({
  method: 'post',
  url: NewUrl + 'api/common/bindingPhone',
  data: params
})

// 获取注册验证码
export const send_captcha = (params) => request({
  method: 'post',
  url: NewUrl + 'web/send_captcha',
  data: params
})

// 新获取注册验证码
export const new_send_captcha = (params) => request({
  method: 'post',
  url: NewUrl + 'api/common/sendCaptchaCode',
  data: params
})

// 获取登录验证码
export const send_captcha_login = (params) => request({
  method: 'post',
  url: NewUrl + 'web/send_captcha_login',
  data: params
})

// 获取师徒开关
export const relation_open = (params) => request({
  method: 'get',
  url: NewUrl + 'system/master_info',
  data: params
})

export const get_notice = (params) => request({
  method: 'get',
  url: NewUrl + 'system/get_notice',
  data: params
})



// 获取公告
// export const get_notice = (params) => request({
//   method: 'get',
//   url: NewUrl + 'api/',//upload/image
//   data: params
// })