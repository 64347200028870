<template>
    <div class="none_data">
        <img src="@/assets/images/nothing.png" alt="">
        <p class="none_data_title">暂无数据哦~</p>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="scss">
    .none_data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 80%;
            height: 90%;
            opacity: .3;
        }
        .none_data_title {
            color: #999;
            position: relative;
            top: -100px;
            font-size: 14px;
        }
    }
</style>